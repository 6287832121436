$(document).ready(function () {
  var loggedInItems = 'LOGGED_IN_ITEMS';
  var $loggedMsg = $('.js-msg-logged');

  $('.js-logged-close').click(function (e) {
    e.preventDefault();

    var watchedItem = $loggedMsg.not('.hidden');
    var watchedItemId = watchedItem.attr('id');

    var watchedItems = $.cookie(loggedInItems);
    watchedItems = watchedItems ? JSON.parse(watchedItems) : [];
    watchedItems.push(watchedItemId);

    $.cookie(loggedInItems, JSON.stringify(watchedItems));
  });


  function checkModalItem() {
    // query all id of important item
    var ids = [];
    $loggedMsg.each(function () {
      var id = $(this).attr('id');
      if (id) {
        ids.push(id);
      }
    });

    var firstMsg;
    var watchedItems = $.cookie(loggedInItems);
    watchedItems = watchedItems ? JSON.parse(watchedItems) : [];
    if (watchedItems.length) {
      watchedItems.forEach(function (id) {
        var index = ids.indexOf(id);
        if (index > -1) {
          ids.splice(index, 1);
        }
      });
    }

    firstMsg = ids.shift();
    if (!firstMsg) {
      $('.js-modal-logged').modal('hide');
      return;
    }

    $('.js-modal-logged').modal('show');
    $('#' + firstMsg).removeClass('hidden');
  }

  checkModalItem();
});

